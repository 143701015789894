<template lang="pug">
om-accordion-item(
  :readingTime="$t('dashboardSections.onboarding.steps.activate.readingTime')"
  :done="done"
  @click="$emit('accordionClick')"
  :disabled="disabled"
  :openDefault="openDefault"
)
  template(#icon)
    img(
      :src="require(`@/assets/admin/svg/onboarding/dashboard-section-${done ? 'done' : 'activate'}.svg`)"
    )
  template(#name) {{ $t('dashboardSections.onboarding.steps.activate.title') }}
  .row.pb-4
    .col-7.align-self-center
      om-body-text.mb-5(bt400lg) {{ $t('dashboardSections.onboarding.steps.activate.description') }}
      om-button(primary large @click="$emit('handleActivate')") {{ $t('dashboardSections.onboarding.steps.activate.cta') }}
    .col-5.text-center.px-5
      om-monks(monk="start")
</template>

<script>
  export default {
    props: {
      done: { type: Boolean, required: true },
      disabled: { type: Boolean, required: true },
      openDefault: { type: Boolean, default: false },
    },
  };
</script>
